import React, { useEffect, useRef, useState } from "react";
import styles from "./Login.module.css";
import loginPageVideo from "../../assets/loginpage.webm";
import microsoftLogo from "../../assets/microsoft-logo.png";
import { useNavigate } from "react-router-dom";

function LoginPage() {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await fetch("/user/auth");
                if (response.status === 200) {
                    navigate("/chat");
                }
            } catch (error) {
                console.error("Authentication check failed:", error);
            }
        };

        checkAuthStatus();
    }, [navigate]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 0.75;
        }
    }, []);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={styles.loginContainer}>
            <video
                autoPlay
                muted
                loop
                className={styles.loginVideo}
                ref={videoRef}
            >
                <source src={loginPageVideo} type="video/webm" />
            </video>
            <div className={styles.navBar}>
                <a
                    href="/login_ms"
                    className={`${styles.navLink} ${styles.active}`}
                >
                    Logga in
                </a>
            </div>
            <div className={styles.loginButtonContainer}>
                <a href="/login_ms" className={styles.loginButton}>
                    <img
                        src={microsoftLogo}
                        alt="Microsoft Logo"
                        className={styles.microsoftLogoInsideButton}
                    />
                    Logga in med Microsoft
                </a>
            </div>
        </div>
    );
}

export default LoginPage;