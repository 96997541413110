import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
    HashRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import CookieConsent from "react-cookie-consent"; // Import CookieConsent

import "./index.css";

import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Users from "./pages/users/Users";
import Insight from "./pages/insight/Insight";
import Logout from "./pages/logout/Logout";
import Sidebar from "./components/Sidebar/Sidebar";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy"; // Import PrivacyPolicy
import TermsOfService from "./pages/terms/TermsOfService"; // Import TermsOfService
import LoginPage from "./pages/login/Login"; // Import LoginPage
import { ChatMessage, fetchSystemMessages } from "./api";

initializeIcons();

// Function to capture browser information
function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let browserName, browserVersion;

    // Check for Firefox
    if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
        browserVersion =
            userAgent.match(/firefox\/([\d.]+)/i)?.[1] ?? "Unknown";
    }
    // Check for Chrome
    else if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
        browserVersion = userAgent.match(/chrome\/([\d.]+)/i)?.[1] ?? "Unknown";
    }
    // Check for Safari
    else if (
        userAgent.match(/safari/i) &&
        !userAgent.match(/chrome|chromium|crios/i)
    ) {
        browserName = "Safari";
        browserVersion =
            userAgent.match(/version\/([\d.]+)/i)?.[1] ?? "Unknown";
    }
    // Add more browsers if needed
    else {
        browserName = "Unknown";
        browserVersion = "Unknown";
    }

    return `${browserName} ${browserVersion}`;
}

// Send browser information to Google Analytics as a custom event
function sendBrowserInfoToGA() {
    const browserInfo = getBrowserInfo();
    window.gtag("event", "browser_info", {
        event_category: "Browser Info",
        event_label: browserInfo,
    });
}

// Track active time on the site
let activeTime = 0;
let lastActiveTime = Date.now();

function handleVisibilityChange() {
    if (document.visibilityState === "visible") {
        lastActiveTime = Date.now(); // Reset last active time
    } else {
        activeTime += Date.now() - lastActiveTime; // Accumulate active time
    }
}

document.addEventListener("visibilitychange", handleVisibilityChange);

// Send active time to Google Analytics before the page unloads
window.addEventListener("beforeunload", () => {
    activeTime += Date.now() - lastActiveTime; // Ensure the last active period is counted
    window.gtag("event", "active_time", {
        event_category: "Engagement",
        event_label: "Active Time",
        value: activeTime / 1000, // Convert milliseconds to seconds
    });
});

// Track site speed using Navigation Timing API
function trackSiteSpeed() {
    const performance = window.performance;
    if (performance) {
        // Use PerformanceNavigationTiming interface for modern browsers
        const navigationEntry = performance.getEntriesByType(
            "navigation",
        )[0] as PerformanceNavigationTiming;
        const pageLoadTime =
            navigationEntry.loadEventEnd - navigationEntry.startTime;
        const domContentLoadedTime =
            navigationEntry.domContentLoadedEventEnd -
            navigationEntry.startTime;

        window.gtag("event", "timing_complete", {
            name: "load",
            value: pageLoadTime,
            event_category: "Site Speed",
            event_label: "Page Load Time",
        });

        window.gtag("event", "timing_complete", {
            name: "DOMContentLoaded",
            value: domContentLoadedTime,
            event_category: "Site Speed",
            event_label: "DOM Content Loaded Time",
        });
    }
}

// Track time on page to influence bounce rate calculation
setTimeout(() => {
    window.gtag("event", "engagement", {
        event_category: "Bounce Rate",
        event_label: "Time on Page",
        value: 30, // User spent more than 30 seconds on the page
    });
}, 30000); // 30 seconds

export default function App() {
    const [conversation, setConversation] = useState<ChatMessage[]>([]);
    const [update, setUpdate] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [convoID, setConvoID] = useState<string>(crypto.randomUUID());

    useEffect(() => {
        const fetchAndDisplayMessages = async () => {
            const response = await fetchSystemMessages();
            if (response.ok) {
                const messages = await response.json();
                messages.forEach(
                    (message: { header: string; body: string }) => {
                        // This section previously contained toast notifications
                    },
                );
            }
        };
        fetchAndDisplayMessages();
        sendBrowserInfoToGA(); // Send browser information to Google Analytics
        trackSiteSpeed(); // Track site speed
    }, []);

    // Function to reset chat state
    const resetChatState = () => {
        setConversation([]);
        setConvoID(crypto.randomUUID());
    };

    return (
        <Router>
            <div style={{ display: "flex" }}>
                <Routes>
                    <Route
                        path="/chat"
                        element={
                            <>
                                <Sidebar
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    minimized={minimized}
                                    setMinimized={setMinimized}
                                    resetChatState={resetChatState} // Pass resetChatState function as prop to Sidebar
                                />
                                <Chat
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    isSidebarMinimized={minimized}
                                    key={"fla"}
                                />
                            </>
                        }
                    />{" "}
                    {/* Pass isSidebarMinimized prop */}
                    <Route
                        path="users"
                        element={
                            <>
                                <Sidebar
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    minimized={minimized}
                                    setMinimized={setMinimized}
                                    resetChatState={resetChatState} // Pass resetChatState function as prop to Sidebar
                                />
                                <Users isSidebarMinimized={minimized} />
                            </>
                        }
                    />
                    <Route
                        path="insight"
                        element={
                            <>
                                <Sidebar
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    minimized={minimized}
                                    setMinimized={setMinimized}
                                    resetChatState={resetChatState} // Pass resetChatState function as prop to Sidebar
                                />
                                <Insight isSidebarMinimized={minimized} />
                            </>
                        }
                    />
                    <Route
                        path="logout"
                        element={
                            <>
                                <Sidebar
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    minimized={minimized}
                                    setMinimized={setMinimized}
                                    resetChatState={resetChatState} // Pass resetChatState function as prop to Sidebar
                                />
                                <Logout />
                            </>
                        }
                    />
                    <Route path="privacy" element={<PrivacyPolicy />} />{" "}
                    {/* Add route for Privacy Policy */}
                    <Route
                        path="terms-of-service"
                        element={<TermsOfService />}
                    />{" "}
                    {/* Add route for Terms of Service */}
                    <Route path="/" element={<LoginPage />} />{" "}
                    {/* Add route for LoginPage */}
                    <Route path="*" element={<NoPage />} />
                    <Route
                        path="/chat/:chatID"
                        element={
                            <>
                                <Sidebar
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    minimized={minimized}
                                    setMinimized={setMinimized}
                                    resetChatState={resetChatState} // Pass resetChatState function as prop to Sidebar
                                />
                                <Chat
                                    answers={conversation}
                                    setAnswers={setConversation}
                                    convoID={convoID}
                                    setConvoID={setConvoID}
                                    update={update}
                                    setUpdate={setUpdate}
                                    isSidebarMinimized={minimized}
                                    key={"fla"}
                                />
                            </>
                        }
                    />
                </Routes>
            </div>
            <Routes>
                <Route path="/" element={<></>} />
                <Route path="/login" element={<></>} />
                <Route
                    path="*"
                    element={
                        <></>
                    }
                />
            </Routes>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="elyxirCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience and to
                analyze performance and traffic on our website. We also share
                information about your use of our site with our social media,
                advertising, and analytics partners.
            </CookieConsent>
        </Router>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

