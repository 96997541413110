import React, { useState, useEffect } from "react";
import styles from "./PromptSuggestions.module.css";

interface PromptSuggestionsProps {
    onPromptSelect: (prompt: string) => void;
}

// 55 character limit
const allPrompts = [
    "Återställa lösenord i AD?",
    "Konfigurera Azure AD Connect?",
    "Skapa ny mailbox i Exchange?",
    "Migrera till Office 365?",
    "Hantera grupper i Active Directory?",
    "Felsöka nätverksanslutning?",
    "Installera Windows 10 via PXE?",
    "Konfigurera VPN för fjärrarbete?",
    "Säkerhetskopiera AD-objekt?",
    "Återställa raderade AD-användare?",
    "Uppdatera GPO:er i domänen?",
    "Hantera licenser i Office 365?",
    "Konfigurera SharePoint Online?",
    "Implementera MFA i Azure?",
    "Optimera lagring i OneDrive?",
    "Felsöka skrivare på nätverket?",
    "Automatisera uppgifter med PowerShell?",
    "Konfigurera Teams för samarbete?",
    "Säkra data med BitLocker?",
    "Använda Intune för enhetshantering?",
    "Åtgärda DNS-problem i domänen?",
    "Hantera certifikat med ADCS?",
    "Upprätta VPN-tunnel till Azure?",
    "Konfigurera dynamisk DNS-uppdatering?",
    "Felsöka autentiseringsproblem?",
    "Implementera logghantering?",
    "Optimera prestanda på Windows Server?",
    "Konfigurera fjärrskrivbordstjänster?",
    "Skapa automatiserade backup-rutiner?",
    "Hantera användarrättigheter effektivt?",
    "Uppdatera firmware på nätverksutrustning?",
    "Konfigurera brandväggsregler?",
    "Övervaka nätverkstrafik?",
    "Analysera loggfiler?",
    "Planera för katastrofåterställning?",
    "Implementera nätverkssegmentering?",
    "Optimera databaser för prestanda?",
    "Säkerställa GDPR efterlevnad?",
    "Konfigurera e-postflöden i Exchange?",
    "Hantera mobila enheter säkert?",
    "Felsöka anslutningsproblem i Teams?",
    "Skapa arbetsflöden med Power Automate?",
    "Använda Azure för applikationshosting?",
    "Optimera kostnader i Azure?",
    "Implementera säkerhetspolicys?",
    "Konfigurera lagringslösningar i molnet?",
    "Felsöka prestandaproblem i Office 365?",
    "Säkra nätverkskommunikation?",
    "Hantera användaridentiteter effektivt?",
    "Automatisera distribution av programvara?",
    "Konfigurera hybridmiljöer med Azure?",
    "Förstå Azure AD B2B samarbete?",
    "Optimera arbetsytan för fjärrarbete?",
    "Implementera Zero Trust säkerhetsmodell?",
];

const PromptSuggestions: React.FC<PromptSuggestionsProps> = ({
    onPromptSelect,
}) => {
    const [currentPrompts, setCurrentPrompts] = useState<string[]>([]);

    useEffect(() => {
        // Function to rotate prompts
        const rotatePrompts = () => {
            const startIndex = Math.floor(
                Math.random() * (allPrompts.length - 4),
            );
            setCurrentPrompts(allPrompts.slice(startIndex, startIndex + 4));
        };

        // Initial rotation
        rotatePrompts();

        // Set interval for rotating prompts every 10 seconds
        const intervalId = setInterval(rotatePrompts, 10000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={styles.container}>
            {currentPrompts.map((prompt, index) => (
                <button
                    key={index}
                    className={styles.promptButton}
                    onClick={() => {
                        onPromptSelect(prompt);
                        window.gtag("event", "select_prompt", {
                            event_category: "Prompts",
                            event_label: prompt,
                        });
                    }}
                >
                    {prompt}
                </button>
            ))}
        </div>
    );
};

export default PromptSuggestions;
