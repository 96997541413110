import {
    useRef,
    useState,
    useEffect,
    useCallback,
    memo,
    lazy,
    Suspense,
} from "react";
import { Stack, Toggle } from "@fluentui/react";
import { DismissRegular, ErrorCircleRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-powershell";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-python";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-bash";

import styles from "./Chat.module.css";
import Azure from "../../assets/LOGO-TRANS.svg";
import aiAvatar from "../../assets/LOGO-TRANS-mini.svg";
import placeholderImage from "../../assets/placeholder.png";

import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    archiveMessage,
    ChatProps,
} from "../../api";
import { Answer } from "../../components/Answer";
import React from "react";
import TypewriterEffect from "../../components/TypewriterEffect/TypewriterEffect";
import MockApiResponse from "../../components/Test/MockApiResponse";
import PromptSuggestions from "../../components/PromptSuggestions/PromptSuggestions";
import { fetchUserPhoto } from "../../hooks/useFetchUserData";
import useAuth from "../../hooks/useAuth"; // Import the custom authentication hook

// Lazy loading heavy components
const DocumentUploader = lazy(
    () => import("../../components/DocumentUploader/DocumentUploader"),
);
const WhatsNewPopup = lazy(
    () => import("../../components/WhatsNewPopup/WhatsNewPopup"),
);

declare global {
    interface Window {
        gtag: any;
    }
}

function useFetchPhoto() {
    const [userPhotoUrl, setUserPhotoUrl] = useState<string>(placeholderImage);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                const photoUrl = await fetchUserPhoto();
                setUserPhotoUrl(photoUrl || placeholderImage);
            } catch (error) {
                setUserPhotoUrl(placeholderImage);
            }
        };

        fetchPhoto();
    }, []);

    return userPhotoUrl;
}

const Chat: React.FC<ChatProps & { isSidebarMinimized: boolean }> = memo(
    ({
        answers,
        setAnswers,
        update = false,
        setUpdate,
        isSidebarMinimized,
        convoID,
        setConvoID,
    }) => {
        useAuth(); // This will check auth status and redirect if not authenticated

        const lastQuestionRef = useRef<string>("");
        const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [showLoadingMessage, setShowLoadingMessage] =
            useState<boolean>(false);
        const [activeCitation, setActiveCitation] =
            useState<
                [
                    content: string,
                    id: string,
                    title: string,
                    filepath: string,
                    url: string,
                    metadata: string,
                ]
            >();
        const [isCitationPanelOpen, setIsCitationPanelOpen] =
            useState<boolean>(false);
        const abortFuncs = useRef([] as AbortController[]);
        const userPhotoUrl = useFetchPhoto();
        const [apiResponseReceived, setApiResponseReceived] =
            useState<boolean>(false);
        const [messageQueue, setMessageQueue] = useState<string[]>([]);
        const [dataRate, setDataRate] = useState<number>(0);
        const [isDataStreaming, setIsDataStreaming] = useState<boolean>(false);
        const [lastDataReceivedTime, setLastDataReceivedTime] =
            useState<number>(Date.now());
        const [hasStartedTyping, setHasStartedTyping] =
            useState<boolean>(false);
        const [isConvoLoaded, setIsConvoLoaded] = useState<boolean>(false);
        const navigate = useNavigate();

        const [inputValue, setInputValue] = useState(""); // State to manage input value
        const [inputError, setInputError] = useState(false); // State to manage input error for character limit
        const [uploadedFileNames, setUploadedFileNames] = useState<string[]>(
            [],
        ); // State to manage the uploaded file names for display
        const [parsedDocumentContent, setParsedDocumentContent] =
            useState<string>(""); // State to store parsed document content
        const [mode, setMode] = useState<"speed" | "precision">("speed"); // State to toggle between GPT 3.5 Turbo and GPT 4

        const resetChatState = () => {
            setAnswers([]);
            setConvoID("");
            setIsConvoLoaded(false);
            setMessageQueue([]);
            setIsLoading(false);
            setShowLoadingMessage(false);
            setIsDataStreaming(false);
            setLastDataReceivedTime(Date.now());
            setHasStartedTyping(false);
            setActiveCitation(undefined);
            setIsCitationPanelOpen(false);
            lastQuestionRef.current = "";
            setInputValue(""); // Reset input value on chat state reset
            setInputError(false); // Reset input error state on chat state reset
            setUploadedFileNames([]); // Reset the uploaded file names on chat state reset
            setParsedDocumentContent(""); // Reset the parsed document content on chat state reset
        };

        const makeApiRequest = useCallback(
            async (question: string) => {
                if (question.length > 8196) {
                    setInputError(true);
                    return;
                }
                setInputError(false);
                lastQuestionRef.current = question;

                setIsLoading(true);
                setIsDataStreaming(true);

                const abortController = new AbortController();
                abortFuncs.current.unshift(abortController);

                const userMessage: ChatMessage = {
                    id: crypto.randomUUID(),
                    role: "user",
                    content: question,
                };

                setAnswers((answers) => [...answers, userMessage]);

                const request: ConversationRequest = {
                    messages: [
                        ...answers.filter((answer) => answer.role !== "error"),
                        userMessage,
                    ],
                    model: mode == "speed" ? "gpt-3.5" : "gpt-4",
                    file_content: parsedDocumentContent,
                };

                let result = {} as ChatResponse;

                const startTime = Date.now();

                try {
                    const response = await conversationApi(
                        request,
                        abortController.signal,
                    );

                    const endTime = Date.now();
                    const apiResponseTime = endTime - startTime;
                    window.gtag("event", "api_response_time", {
                        event_category: "API Performance",
                        event_label: "Chat API Response Time",
                        value: apiResponseTime,
                    });

                    if (response?.body) {
                        const reader = response.body.getReader();
                        let runningText = "";
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) break;

                            var text = new TextDecoder("utf-8").decode(value);

                            const objects = text.split("\n");
                            objects.forEach((obj) => {
                                try {
                                    runningText += obj;
                                    result = JSON.parse(runningText);

                                    setShowLoadingMessage(false);
                                    const nonEmptyAssistantMessages =
                                        result.choices[0].messages.filter(
                                            (msg) =>
                                                msg.role === "assistant" &&
                                                msg.content.trim() !== "",
                                        );
                                    setMessageQueue([
                                        ...messageQueue,
                                        ...nonEmptyAssistantMessages.map(
                                            (msg) => msg.content,
                                        ),
                                    ]);
                                    runningText = "";
                                } catch {}
                            });

                            const currentTime = Date.now();
                            const timeDiff = currentTime - lastDataReceivedTime;
                            setLastDataReceivedTime(currentTime);
                            if (timeDiff > 0) {
                                const rate = 1000 / timeDiff;
                                setDataRate(rate);
                            }
                        }

                        const qa_id = crypto.randomUUID();
                        const finalResponse =
                            result.choices[0].messages[1] == undefined
                                ? result.choices[0].messages[0].content
                                : result.choices[0].messages[1].content;

                        archiveMessage({
                            conversation_id: convoID,
                            qa_id: qa_id,
                            question_content: userMessage.content,
                            answer_content: finalResponse,
                        });
                        setIsConvoLoaded(true);
                        // Extract the new conversation ID from the API response correctly
                        const newConvoID = result.newConvoID; // Correctly extracting new conversation ID
                        if (newConvoID) {
                            setConvoID(newConvoID);
                            navigate(`/chat/${newConvoID}`); // Navigate to the new conversation
                        }
                    }
                    // Check if the API response took longer than 3 seconds and display the popup
                    if (apiResponseTime > 5000) {
                        Swal.fire({
                            title: "Vi upplever just nu hög belastning på våra servrar, tack för ditt tålamod.",
                            toast: true,
                            position: "top",
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    "mouseenter",
                                    Swal.stopTimer,
                                );
                                toast.addEventListener(
                                    "mouseleave",
                                    Swal.resumeTimer,
                                );
                            },
                        });
                    }
                } catch (e) {
                    if (!abortController.signal.aborted) {
                        let errorMessage =
                            "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                        if (result.error?.message) {
                            errorMessage = result.error.message;
                        } else if (typeof result.error === "string") {
                            errorMessage = result.error;
                        }
                        setAnswers([
                            ...answers,
                            userMessage,
                            {
                                id: crypto.randomUUID(),
                                role: "error",
                                content: errorMessage,
                            },
                        ]);
                    } else {
                        setAnswers([...answers, userMessage]);
                    }
                } finally {
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(
                        (a) => a !== abortController,
                    );
                    setIsDataStreaming(false); // Ensure streaming is stopped on error or completion
                }

                return abortController.abort();
            },
            [
                answers,
                setAnswers,
                conversationApi,
                setConvoID,
                navigate,
                setMessageQueue,
                setIsLoading,
                setIsDataStreaming,
                setShowLoadingMessage,
                setIsConvoLoaded,
            ],
        );

        const stopGenerating = useCallback(() => {
            abortFuncs.current.forEach((a) => a.abort());
            setShowLoadingMessage(false);
            setIsLoading(false);
            setIsDataStreaming(false);
            window.gtag("event", "stop_generating", {
                event_category: "Button",
                event_label: "Stop Elyxir",
            });
        }, [setShowLoadingMessage, setIsLoading, setIsDataStreaming]);

        useEffect(
            () =>
                chatMessageStreamEnd.current?.scrollIntoView({
                    behavior: "smooth",
                }),
            [answers.length],
        );

        useEffect(() => {
            if (!isLoading && isConvoLoaded) {
                chatMessageStreamEnd.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }, [isLoading, isConvoLoaded]);

        const onShowCitation = useCallback(
            (citation: Citation) => {
                setActiveCitation([
                    citation.content,
                    citation.id,
                    citation.title ?? "",
                    citation.filepath ?? "",
                    "",
                    "",
                ]);
                setIsCitationPanelOpen(true);
            },
            [setActiveCitation, setIsCitationPanelOpen],
        );

        const parseCitationFromMessage = useCallback((message: ChatMessage) => {
            if (message.role === "tool") {
                try {
                    const toolMessage = JSON.parse(
                        message.content,
                    ) as ToolMessageContent;
                    return toolMessage.citations;
                } catch {
                    return [];
                }
            }
            return [];
        }, []);

        useEffect(() => {
            if (update) {
                lastQuestionRef.current = "update";
                setUpdate(false);
            }
        }, [update]);

        const handleFocus = useCallback(() => {
            window.gtag("event", "focus", {
                event_category: "Input",
                event_label: "Chat Input Focus",
            });
        }, []);

        const handleInput = useCallback(
            (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInputValue(event.target.value);
                if (event.target.value.length > 8196) {
                    setInputError(true);
                } else {
                    setInputError(false);
                }
            },
            [],
        );

        const handleKeyPress = useCallback(
            (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (
                    !isDataStreaming &&
                    event.key === "Enter" &&
                    !event.shiftKey
                ) {
                    event.preventDefault();
                    if (inputValue.trim() !== "" && inputValue.length <= 8196) {
                        makeApiRequest(inputValue.trim());
                        setInputValue(""); // Clear input after sending
                        setUploadedFileNames([]); // Clear uploaded file names after sending
                    }
                } else if (event.key === "Enter" && event.shiftKey) {
                    event.preventDefault();
                    setInputValue(inputValue + "\n");
                }
            },
            [inputValue, isDataStreaming, makeApiRequest],
        );

        useEffect(() => {
            setHasStartedTyping(false);
        }, [answers]);

        useEffect(() => {
            Prism.highlightAll();
        }, [answers, messageQueue, convoID]);

        useEffect(() => {
            if (convoID) {
                navigate(`/chat/${convoID}`);
                chatMessageStreamEnd.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }, [convoID, navigate]);

        // Implement the document parsing logic within handleFileUpload
        const handleFileUpload = async (file: File) => {
            console.log("File uploaded:", file.name);
            setUploadedFileNames((prevFiles) => {
                // Limit the number of files to 3
                const updatedFiles = [...prevFiles, file.name];
                if (updatedFiles.length > 3) {
                    Swal.fire({
                        title: "Max tre filer per meddelande",
                        toast: true,
                        position: "top",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                "mouseenter",
                                Swal.stopTimer,
                            );
                            toast.addEventListener(
                                "mouseleave",
                                Swal.resumeTimer,
                            );
                        },
                    });
                    return prevFiles; // Return previous files without adding new one
                }
                return updatedFiles.slice(0, 3);
            }); // Update state with the file names

            // Example parsing logic for text files
            if (file.type === "text/plain") {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const text = e.target?.result;
                    if (text) {
                        // Added null-check for 'text'
                        console.log("Parsed text:", text);
                        // Here you can set the parsed text for further processing or API calls
                        setParsedDocumentContent(text.toString()); // Update state with the parsed document content
                    }
                };
                reader.readAsText(file);
            } else {
                console.log(
                    "Unsupported file type. Currently, only text files are supported.",
                );
                // Placeholder for future extension to support other file types like PDF, DOCX, etc.
            }
        };

        const handlePaste = (
            event: React.ClipboardEvent<HTMLTextAreaElement>,
        ) => {
            const items = event.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === "file") {
                    const file = items[i].getAsFile();
                    if (file) {
                        handleFileUpload(file);
                        event.preventDefault(); // Prevent the default paste action
                    }
                }
            }
        };

        const handleDrop = (event: React.DragEvent<HTMLTextAreaElement>) => {
            event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                handleFileUpload(files[0]);
            }
        };

        // UI element to display the uploaded file icon and name
        const UploadedFileIndicator = () => {
            if (!uploadedFileNames.length) return null;

            return (
                <div
                    className={styles.uploadedFileIndicator}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                    }}
                >
                    {uploadedFileNames.map((fileName, index) => {
                        let icon = "description"; // default icon
                        if (
                            fileName.endsWith(".docx") ||
                            fileName.endsWith(".pdf")
                        ) {
                            icon = "article";
                        } else if (
                            fileName.endsWith(".jpg") ||
                            fileName.endsWith(".png")
                        ) {
                            icon = "image";
                        } else if (fileName.endsWith(".csv")) {
                            icon = "table_chart";
                        } else if (fileName.endsWith(".xlsx")) {
                            icon = "grid_on";
                        }

                        return (
                            <div
                                key={index}
                                className={styles.uploadedFileIndicator}
                            >
                                <span className="material-icons-sharp">
                                    {icon}
                                </span>
                                <span>{fileName}</span>
                            </div>
                        );
                    })}
                </div>
            );
        };

        return (
            <div className={styles.container} role="main">
                <Suspense fallback={<div>Loading...</div>}>
                    <WhatsNewPopup />
                </Suspense>
                <MockApiResponse
                    setApiResponseReceived={setApiResponseReceived}
                    setMessageQueue={setMessageQueue}
                    answersLength={answers.length}
                />
                <Stack horizontal className={styles.chatRoot}>
                    <div
                        className={`${styles.chatContainer} ${isSidebarMinimized ? styles.chatContainerExpanded : ""}`}
                    >
                        {!lastQuestionRef.current ? (
                            <div className={styles.chatEmptyStateWrapper}>
                                <Stack className={styles.chatEmptyState}>
                                    <img
                                        src={Azure}
                                        className={styles.chatIcon}
                                        aria-hidden="true"
                                        loading="lazy"
                                    />
                                    <h1 className={styles.chatEmptyStateTitle}>
                                        Börja prata med Elyxir
                                    </h1>
                                    <h2
                                        className={
                                            styles.chatEmptyStateSubtitle
                                        }
                                    >
                                        Elyxir är en del av Molarisoft
                                    </h2>
                                    <PromptSuggestions
                                        onPromptSelect={(prompt) =>
                                            makeApiRequest(prompt)
                                        }
                                    />
                                </Stack>
                            </div>
                        ) : (
                            <div
                                className={styles.chatMessageStream}
                                style={{
                                    marginBottom: isLoading ? "40px" : "0px",
                                }}
                                role="log"
                            >
                                {answers.map((answer, index) => (
                                    <React.Fragment key={answer.id}>
                                        {answer.role === "user" ? (
                                            <div
                                                className={
                                                    styles.chatMessageUser
                                                }
                                                tabIndex={0}
                                            >
                                                <img
                                                    src={userPhotoUrl}
                                                    className={`${styles.userPhoto} ${styles.chatAvatarUser}`}
                                                    alt="User"
                                                    loading="lazy"
                                                />
                                                <div>
                                                    <strong
                                                        className={
                                                            styles.chatMessageLabel
                                                        }
                                                    >
                                                        You
                                                    </strong>
                                                    <div
                                                        className={
                                                            styles.chatMessageUserMessage
                                                        }
                                                    >
                                                        {answer.content}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : answer.role === "assistant" ? (
                                            <div
                                                className={`${styles.chatMessageGpt} ${styles.chatMessageGpt}`}
                                                tabIndex={0}
                                            >
                                                <img
                                                    src={aiAvatar}
                                                    className={
                                                        styles.chatAvatarAI
                                                    }
                                                    alt="AI"
                                                    loading="lazy"
                                                />
                                                <div>
                                                    <strong
                                                        className={
                                                            styles.chatMessageLabel
                                                        }
                                                    >
                                                        Elyxir
                                                    </strong>
                                                    <Answer
                                                        answer={{
                                                            answer: answer.content,
                                                            citations:
                                                                parseCitationFromMessage(
                                                                    answers[
                                                                        index -
                                                                            1
                                                                    ],
                                                                ),
                                                        }}
                                                        onCitationClicked={(
                                                            c,
                                                        ) => onShowCitation(c)}
                                                    />
                                                    <div
                                                        className="material-icons-sharp copy-icon"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                answer.content,
                                                            );
                                                            Swal.fire({
                                                                title: "Meddelande kopierat",
                                                                toast: true,
                                                                position: "top",
                                                                showConfirmButton:
                                                                    false,
                                                                timer: 3000,
                                                                timerProgressBar:
                                                                    true,
                                                                didOpen: (
                                                                    toast,
                                                                ) => {
                                                                    toast.addEventListener(
                                                                        "mouseenter",
                                                                        Swal.stopTimer,
                                                                    );
                                                                    toast.addEventListener(
                                                                        "mouseleave",
                                                                        Swal.resumeTimer,
                                                                    );
                                                                },
                                                            });
                                                        }}
                                                        style={{
                                                            opacity: 0.3,
                                                            transform:
                                                                "scale(0.75)",
                                                            cursor: "default",
                                                        }}
                                                        title="Kopiera svar" // Tooltip added
                                                    >
                                                        content_copy
                                                    </div>
                                                </div>
                                            </div>
                                        ) : answer.role === "error" ? (
                                            <div
                                                className={
                                                    styles.chatMessageUser
                                                }
                                                tabIndex={0}
                                            >
                                                <ErrorCircleRegular
                                                    className={styles.errorIcon}
                                                    style={{
                                                        color: "rgba(182, 52, 67, 1)",
                                                    }}
                                                />
                                                <div>
                                                    <strong
                                                        className={
                                                            styles.chatMessageLabel
                                                        }
                                                    >
                                                        Error
                                                    </strong>
                                                    <div
                                                        className={
                                                            styles.chatMessageUserMessage
                                                        }
                                                    >
                                                        {answer.content}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ))}
                                {messageQueue.length > 0 && (
                                    <TypewriterEffect
                                        messageQueue={messageQueue}
                                        onTypingComplete={(message: string) => {
                                            setMessageQueue((prevQueue) =>
                                                prevQueue.slice(1),
                                            );
                                            setAnswers((answers) => [
                                                ...answers,
                                                {
                                                    id: crypto.randomUUID(),
                                                    role: "assistant",
                                                    content: message,
                                                },
                                            ]);
                                            setApiResponseReceived(false);
                                            chatMessageStreamEnd.current?.scrollIntoView(
                                                { behavior: "smooth" },
                                            );
                                        }}
                                        onTypingStart={() => {
                                            chatMessageStreamEnd.current?.scrollIntoView(
                                                { behavior: "smooth" },
                                            );
                                        }}
                                        dataRate={dataRate}
                                        isDataStreaming={isDataStreaming}
                                    />
                                )}
                                {showLoadingMessage && (
                                    <div className={styles.chatMessageGpt}>
                                        <img
                                            src={aiAvatar}
                                            className={styles.chatAvatarAI}
                                            alt="AI"
                                            loading="lazy"
                                        />
                                        <div
                                            className={
                                                styles.chatMessageGptThinking
                                            }
                                        >
                                            <span
                                                className={styles.blinkingDot}
                                            ></span>
                                        </div>
                                    </div>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <div className={styles.modeToggleContainer}>
                            <Toggle
                                label="Mode"
                                inlineLabel
                                onText="Precision"
                                offText="Speed"
                                onChange={(e, checked) =>
                                    setMode(checked ? "precision" : "speed")
                                }
                                checked={mode === "precision"}
                                disabled={isDataStreaming} // Disable the toggle when data is streaming
                            />
                        </div>

                        <UploadedFileIndicator />

                        <Stack horizontal className={styles.chatInput}>
                            {isLoading && (
                                <Stack
                                    horizontal
                                    className={styles.stopGeneratingContainer}
                                    role="button"
                                    aria-label="Stoppa Elyxir"
                                    tabIndex={0}
                                    onClick={stopGenerating}
                                    onKeyDown={(e) =>
                                        e.key === "Enter" || e.key === " "
                                            ? stopGenerating()
                                            : null
                                    }
                                >
                                    <div className={styles.spinningIcon}></div>
                                    <span
                                        className={styles.stopGeneratingText}
                                        aria-hidden="true"
                                    >
                                        Stoppa Elyxir
                                    </span>
                                </Stack>
                            )}
                            <textarea
                                className={styles.questionInput}
                                placeholder="Skriv här för att börja chatta med Elyxir..."
                                disabled={isDataStreaming} // Disable input when AI is typing
                                value={inputValue}
                                onChange={handleInput}
                                onKeyPress={handleKeyPress}
                                onFocus={handleFocus}
                                onPaste={handlePaste}
                                onDrop={handleDrop}
                            />
                            <div
                                className={
                                    isDataStreaming
                                        ? styles.stopButton
                                        : styles.sendButton
                                }
                                onClick={() => {
                                    if (
                                        !isDataStreaming &&
                                        inputValue.trim() !== "" &&
                                        inputValue.length <= 8196
                                    ) {
                                        makeApiRequest(inputValue.trim());
                                        setInputValue(""); // Clear input after sending
                                        setUploadedFileNames([]); // Clear uploaded file names after sending
                                    }
                                }}
                            ></div>
                        </Stack>
                    </div>
                    {answers.length > 0 &&
                        isCitationPanelOpen &&
                        activeCitation && (
                            <Stack.Item
                                className={styles.citationPanel}
                                tabIndex={0}
                                role="tabpanel"
                                aria-label="Citations Panel"
                            >
                                <Stack
                                    horizontal
                                    className={
                                        styles.citationPanelHeaderContainer
                                    }
                                    horizontalAlign="space-between"
                                    verticalAlign="center"
                                >
                                    <span
                                        className={styles.citationPanelHeader}
                                    >
                                        Citations
                                    </span>
                                    <DismissRegular
                                        className={styles.citationPanelDismiss}
                                        onClick={() =>
                                            setIsCitationPanelOpen(false)
                                        }
                                    />
                                </Stack>
                                <h5
                                    className={styles.citationPanelTitle}
                                    tabIndex={0}
                                >
                                    {activeCitation[2]}
                                </h5>
                                <div tabIndex={0}>
                                    <ReactMarkdown
                                        className={styles.citationPanelContent}
                                        children={activeCitation[0]}
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                </div>
                            </Stack.Item>
                        )}
                </Stack>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.answers === nextProps.answers &&
            prevProps.isSidebarMinimized === nextProps.isSidebarMinimized &&
            prevProps.convoID === nextProps.convoID &&
            prevProps.update === nextProps.update
        );
    },
);

export default Chat;
